// ===============首頁header 事件
let deskCtrolbar = $('header .header-content.desk .headerInfo-ctrol ul li')
let rwdCtrolbar = $('header .header-content.rwd .headerInfo-ctrol ul li')
let deskCtrolContent = $('header .header-content.desk .headerInfo')
let rwdCtrolContent = $('header .header-content.rwd .headerInfo')

// 桌機頁簽切換
deskCtrolbar.on('click', function(){
    $(this).siblings().removeClass('active')
    $(this).addClass('active')
    
    for(let i=0; i<deskCtrolContent.length; i++){        
        if(deskCtrolContent.eq(i).attr('id') == $(this).attr('data-location')){
            deskCtrolContent.eq(i).siblings('.headerInfo').fadeOut(250)
            deskCtrolContent.eq(i).delay(250).fadeIn(250)
        }
    }
})

// rwd頁簽切換
rwdCtrolbar.on('click', function(){
    $(this).siblings().removeClass('active')
    $(this).addClass('active')
    
    for(let i=0; i<rwdCtrolContent.length; i++){        
        if(rwdCtrolContent.eq(i).attr('id') == $(this).attr('data-location')){
            rwdCtrolContent.eq(i).siblings('.headerInfo').fadeOut(250)
            rwdCtrolContent.eq(i).delay(300).fadeIn()
        }
    }
})

// rwd漢堡
let rwdheaderBtn = $('header .header-content.rwd .rwd-btn')
let rwdheaderContent = $('header .header-content.rwd .header-container')
let rwdheaderContentCloseBtn = $('header .header-content.rwd .header-container .buttons .close-btn')

rwdheaderBtn.on('click', function(){
    rwdheaderContent.slideToggle();
})

rwdheaderContentCloseBtn.on('click', function(){
    rwdheaderContent.slideToggle();
})
// ===============首頁header 事件結束

// dragable 首頁抓抓
function indexDragble() {
    if($(window).width()>1024){
        Draggable.create("#index-animate", {
            bounds:$('#index-container'),
            type:"top,left",
            throwProps:true,
            autoScroll:false,
            inertia:true,
            dragClickables:true
        });
    }
}

$(window).on('load', function(){
    indexDragble()
})

$(window).on('resize', function(){
    indexDragble()
})